/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

*{
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Lato', sans-serif;
}

.contact_head{
    padding-left: 5vw;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.15)),
    url("../assets/contact_banner.jpg") no-repeat center center/cover;
    margin-top: 5.6rem;
    height: 36rem;
}

.contact_head > h1{
    padding-top: 12rem;
    font-size: 4.5em;
    font-weight: 500;
}

.contact_head > h2{
    margin-top: 1rem;
    font-size: 1.7em;
    font-weight: 400;
    padding-right: 35vw;
}

.address{
    /* padding-left: 5vw; */
    margin-top: 2vw;
    display: flex;
    gap: 18vw;
}

.address h2{
    font-weight: 500;
    font-size: 1.8em;
}

.address a{
    color: #fff;
    font-size: 1.2vw;
    transition: all ease 0.3s;
}
.address a:hover{
    color: #9B8168;
    transition: all ease 0.3s;
}

.address > div div{
    display: flex;
    flex-flow: column;
    gap: 0.5vw;
}

.address > div div a > p{
    margin-top: 0.5vw;
}
.addressContent p{
    cursor: default;
    font-size: 1.1vw;
}

.address_bar{
    border-right: 1px solid #000;
    height: 18rem;
}

.contact_Form_head{
    margin-top: 8vw;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.contact_Form_head h1:nth-child(1){
    color: #ffffff4d;
}

.contact_Form_head h1{
    font-size: 3.3em;
    font-weight: 500;
}

.contact_form{
    display: flex;
    /* flex-flow: column; */
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 6vw;
    margin-top: 5vw;
    gap: 7vw;
}

.container{
    margin-top: 2.5vw;
}

.contactImg{
    height: 20vw;
}

.Form{
    margin-top: 2vw;
    transition: all ease-in 0.5s;
}

.Form > form{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.contact_form h2{
    color: #bd8600;
    border-bottom: 1px solid #bd8600;
    cursor: pointer;
    padding-bottom: 3px;
    font-size: 1.6em;
    font-weight: 400;
    transition: all ease-in 0.5s;
}

.Form label{
    font-size: 16px;
    line-height: 160%;
    font-weight: 500;
    color: #F4F0E1;
    font-family: "Manrope";
    text-align: start;
    width: 100%;
}

.Form label > span{
    color: #ff100d;
    font-size: 13px;
}

.Form input, .Form textarea{
    width: 480px;
    height: 50px;
    background: #FFF;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 16px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 160%;
    font-weight: 300;
    letter-spacing: .01em;
    outline: none;
}

.Form textarea{
    height: 12rem;
    padding-top: 10px;
}

.Form button{
    outline: none;
    border: none;
    color: #fff;
    background: #9B8168;
    margin-top: 1.5rem;
    padding: 1rem 1.8rem;
    font-family: "Manrope";
    font-size: 16px;
    font-weight: 700;
    transition: all ease 0.3s;
}
.Form button:hover{
    background: #835d38;
}

.contactFlex{
    flex-flow: row !important;
    margin-top: 2rem;
}

.contactFlex svg{
    margin-top: 0.3vw;
}
.Timing svg{
    margin-top: 0vw;
}
.Timing{
    cursor: default;
    font-size: 1.1vw;
}

@media screen and (max-width: 1000px){
    .Form input, .Form textarea {
        width: 45vw;
        height: 5vw;
        font-size: 2.3vw;
    }
}
@media screen and (max-width: 550px){
    .contact_head {
        margin-top: 20vw;
    }
    .contact_head > h1 {
        padding-top: 10rem;
        font-size: 4.2em;
    }
    .contact_head > h2 {
        font-size: 1.5em;
        padding-right: 5vw;
    }
    .contact_Form_head h1 {
        font-size: 6.8vw;
        text-align: center;
    }
    .contact_form {
        flex-wrap: wrap-reverse;
        margin-bottom: 15vw;
        gap: 15vw;
    }
    .Form input, .Form textarea {
        width: 90vw;
        height: 9.5vw;
        margin-top: 0px;
        font-size: 4vw;
    }
    .Form textarea {
        height: 11rem;
        padding-top: 0.8vw;
    }
    .contactImg {
        height: 60vw;
    }
    .address a {
        font-size: 5vw;
        line-height: 7vw;
    }
    .addressContent p {
        font-size: 3.5vw;
    }
    .address > div div {
        gap: 1.5vw;
    }
    .Timing {
        font-size: 3.2vw;
    }
}