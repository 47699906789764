.article__div5 {
    position: relative;
    width: 70vw;
    margin-top: 4vw;
    margin-left: auto;
    margin-right: auto;
}

.article__div3 {
    position: relative;
    margin-top: 10vw;
    font-size: 3vw;
    margin-left: 5vw;
}

.carousel .slide img {
    width: 80% !important;
    vertical-align: top;
    border: 0;
}

@media screen and (max-width: 550px){
    .article__div1{
        margin-top: 25vw;
    }
    .article__div3 {
        font-size: 4.2vw;
        margin-left: 0vw;
    }
    .article__div4 p{
        margin-left: 5vw;
    }
    .article__div5 {
        width: 95vw;
    }
}