#commercial_pyramid{
  left: 0;
}
.buy__div1 {
  /* margin-bottom: 25vw; */
  position: relative;
  margin-top: 0vw;
  top: -5vw;
}
.buy__div3 {
  margin-top: 2vw;
  font-size: 1.7vw;
  color: #fff;
  /* z-index: 1; */
}
.buy__img1 {
  height: 45vw;
  width: 100%;
  opacity: 0.7;
  position: relative;
  margin-top: 5vw;
  object-fit: cover;
}
.buy__div4 {
  border: 0.01vw solid #fff;
  width: 12vw;
  margin-top: 0.4vw;
}
.buy__div04 {
  position: relative;
  margin-top: -26vw;
  margin-left: 5vw;
}

.buy__div04 > p:nth-child(1) {
  font-size: 5vw;
}

.buy__div04 > p:nth-child(2) {
  font-size: 1.5vw;
  margin-top: 1.5vw;
  width: 53vw;
}

.buy__div5 {
  margin-top: 23vw;
  /* margin-left: 5vw; */
  /* margin-right: 5vw; */
}
.buy__div6 {
  font-size: 2.5vw;
  width: 78vw;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}
.buy__div06 {
  display: flex;
  position: relative;
  left: -5vw;
  flex-direction: row-reverse;
}
.buy__div7 {
  margin-top: -6vw;
}
.buy__img02 {
  position: relative;
  left: 5vw;
  height: 31vw;
}
.buy__div08 {
  font-size: 4.9vw;
  width: 30vw;
  line-height: 5vw;
}
.buy__div08 span {
  color: rgba(255, 255, 255, 0.3);
}
.buy__img03 {
  height: 31vw;
  position: relative;
  left: -5vw;
}
.buy__div80 {
  margin-left: 5vw;
}
.buy__div007 {
  margin-left: 0vw;
  margin-top: 5vw;
}
.about__div0011 {
  border: 0.01vw solid #fff;
  opacity: 0.6;
  width: 10.3vw;
  margin-top: 0.3vw;
}
.buy__div008 {
  font-size: 3.9vw;
  width: 40vw;
  line-height: 5vw;
}
.buy__div9 {
  position: relative;
  margin-top: -12vw;
}
.buy__div10 {
  margin-top: 1vw;
  color: #9b8168;
  text-decoration: underline #9b8168;
}
.buy__div11 {
  margin-top: 5vw;
  margin-left: 4.2vw;
  margin-right: 3vw;
  display: flex;
}
.buy__div12 {
  display: flex;
  background-color: #c7ccd1;
  margin-left: -5vw;
  margin-right: -5vw;
  position: relative;
  color: #000;
}
.buy__div13 {
  padding: 6vw;
}
.buy__div13 p:nth-child(1) {
  font-size: 3vw;
}
.buy__div13 p:nth-child(2) {
  font-size: 1.4vw;
  margin-top: 3vw;
  line-height: 2.3vw;
  width: 44vw;
}

.buy__div14 {
  display: flex;
  margin-top: 5vw;
}
.buy__div14 div {
  margin-left: -22.5vw;
}
.buy__div14 div:nth-child(1) {
  margin-left: 0vw;
}
.buy__div16 {
  display: flex;
  align-items: center;
}
.buy__div16 > p {
  font-size: 1.2vw !important;
  margin-left: 0.6vw !important;
}
.buy__div15 > p {
  font-size: 1.15vw !important;
  width: 18vw !important;
  line-height: 1.8vw !important;
}
.buy__div014 {
  display: flex;
  margin-top: 1vw !important;
}
.buy__div014 div {
  margin-left: -22.5vw;
}
.buy__div014 div:nth-child(1) {
  margin-left: 0vw;
}
.buy__img5 {
  height: 1.1vw;
  margin-top: 3vw;
}
.buy__img6 {
  height: 30vw;
  position: relative;
  left: -22.2vw;
}
.buy__div18 {
  margin-top: 10vw;
}
.buy__div19 {
  display: flex;
}
.buy__img7 {
  height: 30vw;
}
.buy__div21 {
  background-color: #9b8168;
  padding: 10vw;
  position: relative;
  left: -18vw;
  z-index: -999;
  height: 60vw !important;
  /* width: 80vw !important; */
}
.buy__div021 {
  background-color: #9b8168;
  padding: 10vw;
  position: relative;
  left: -18vw;
  z-index: -999;
  height: 80vw !important;
}
.buy__div22 {
  position: relative;
  left: 1vw;
  width: 55vw;
}
.buy__div022 {
  margin-left: 12vw;
}
.buy__div022 p:nth-child(1) {
  font-size: 3.4vw;
  width: 40vw;
}
.buy__div022 p:nth-child(2) {
  margin-top: 1vw;
  font-size: 1.3vw;
  line-height: 1.9vw;
}
.buy__div23 > p {
  margin-top: 2vw;
  font-size: 1.8vw !important;
}
.buy__div24 {
  border: 0.01vw solid #fff;
  margin-top: 0.3vw;
  width: 13.6vw;
}
.buy__div25 {
  margin-top: 5vw;
  padding: 3vw;
}
.buy__div26 > p {
  font-size: 3vw;
  margin-top: 3vw;
}
.buy__div27 {
  font-size: 7vw;
  position: relative;
  margin-left: -3vw;
  margin-right: -3vw;
  margin-top: 4vw;
}
.buy__div27 p {
  margin-left: 3vw;
  color: #373737;
}
.sell__div5 {
  margin-bottom: 10vw;
  position: relative;
  top: -11vw;
}
.sell__div4 {
  top: 10vw;
  position: relative;
}
.sell__div06 {
  position: relative;
  top: 8vw;
}
.sell__div011 {
  border: 0.01vw solid #fff;
  opacity: 0.6;
  width: 7vw;
  margin-top: 0.3vw;
}
.rent__div011 {
  border: 0.01vw solid #fff;
  opacity: 0.6;
  width: 8.3vw;
  margin-top: 0.3vw;
}
.rent__div0011 {
  border: 0.01vw solid #fff;
  opacity: 0.6;
  width: 8.3vw;
  margin-top: 0.3vw;
}
.rent__div017 {
  margin-top: 2vw;
}
.rent__div017 > p {
  font-size: 1.4vw;
}
.rent__div3 {
  position: relative;
  top: -15vw;
}
.rent__div6 {
  margin-top: 1vw;
  margin-left: 2vw;
  font-size: 1.4vw;
}
.rent__div0199 {
  position: relative;
  top: -10vw;
}
li {
  margin-top: 0.8vw;
  width: 40vw;
  line-height: 2vw;
}
.rent__div021 {
  background-color: #9b8168;
  padding: 10vw;
  position: relative;
  left: -18vw;
  z-index: -999;
  height: 80vw !important;
  /* width: 80vw !important; */
}
.rent__div10 {
  position: relative;
  top: -24vw;
}



/* SUB-Service */

.sub_services{
  display: flex;
  flex-flow: column;
  margin-top: -16vw;
}
.sub_service_div{
  display: flex;
  align-items: flex-start;
    justify-content: center;
    gap: 5vw;
    margin-top: 3vw;
}
.serviceBox {
  width: 27vw;
}
.serviceBox img{
  height: 18vw;
  width: 27vw;
}
.serviceBox h1{
  margin-top: 2vw;
  font-size: 2vw;
  line-height: 2.5vw;
}
.serviceBox p{
  margin-top: 1vw;
  font-size: 1.2vw;
  line-height: 1.4vw;
}


@media screen and (max-width: 550px){
  .buy__img1 {
    height: 100vw;
    margin-top: 20vw;
  }
  .buy__div04 > p:nth-child(2) {
    font-size: 3.3vw;
    margin-top: 1vw;
    line-height: 4vw;
    width: 80vw;
  }
  .buy__div04 > p:nth-child(1) {
    font-size: 10vw;
  }
  .buy__div04 {
    margin-top: -45vw;
}
.serviceBox {
  width: 90vw;
}
.sub_service_div {
  flex-wrap: wrap;
  gap: 10vw;
}
.sub_services {
  margin-top: 10vw;
}
.serviceBox img {
  height: 60vw;
  width: 90vw;
}
.serviceBox h1 {
  font-size: 6vw;
  line-height: 6.5vw;
}
.serviceBox p {
  font-size: 3.8vw;
  line-height: 4.4vw;
}
}