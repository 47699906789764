.navbar__div1 {
  overflow-x: hidden;
  /* width: 100%; */
  z-index: 9999;
  position: relative;
  /* overflow-y: hidden; */
}

.navbar__div2 {
  position: fixed;
  top: 0vw;
  padding: 1vw;
  border: 0.01vw solid #333;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  z-index: 9999;
  overflow: hidden;
  /* background-color: #171717; */
  background-color: #00001a;
  color: #fff;
}
.navbar__div3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
}
.navbar__div4 {
  display: flex;
  align-items: center;
  margin-left: 1vw;
  /* position: relative; */
  /* border-right: 0.01vw solid #fff; */
}
.navbar__div4::after {
  content: "";
    width: 7vw;
    rotate: 90deg;
    border: 0.01vw solid #333;
    margin-left: 1vw;
}
.navbar__div4 > p {
  margin-left: 0.8vw;
  font-size: 1.2vw;
  font-weight: 500;
}
.navbar__logo {
  height: 1.2vw;
}
.navbar__div5 {
  /* margin-left: 20vw; */
  margin-top: 0.5vw;
  margin-left: -0.5vw;
}
.cmpLogo {
  height: 4.5vw !important;
}
.navbar__div6 {
  display: flex;
  align-items: center;
  position: relative;
  left: -1.5vw;
}
.navbar__div6::before {
  content: "";
  width: 7vw;
  rotate: 90deg;
  border: 0.01vw solid #333;
  /* margin-left: -7vw;
    position: absolute; */
}
.navbar__div6 > p {
  margin-left: 0.8vw;
  font-size: 1.2vw;
  font-weight: 500;
}
.second__icon {
  height: 1.2vw;
}

.navbar__div7 {
  overflow: hidden;
}

.nav__div000000 {
  display: flex;
  font-size: 1.6vw !important;
  margin-top: 2vw;
}

.nav__div000000 a {
  margin-left: 1vw;
  margin-right: 1vw;
}
.nav__div000000 a:nth-child(1) {
  margin-left: 0.4vw;
}



@media (max-width: 550px) { 
  .liness{
    display: none;
  }
  .navbar__div1 {
    overflow-x: hidden;
    /* width: 100%; */
    z-index: 9999;
    position: relative;
    /* overflow-y: hidden; */
  }
  
  .navbar__div2 {
    position: fixed;
    top: 0vw;
    padding: 6vw;
    border: 0.05vw solid #333;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    z-index: 9999;
    overflow: hidden;
    /* background-color: #171717; */
    background-color: #00001a;
    color: #fff;
  }
  .navbar__div3 {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
  }
  .navbar__div4 {
    display: flex;
    align-items: center;
    margin-left: 1vw;
    /* position: relative; */
    /* border-right: 0.01vw solid #fff; */
  }
  .navbar__div4::after {
    content: "";
    width: 19.5vw;
    rotate: 90deg;
    border: 0.2vw solid #333;
    background-color: #333;
    margin-left: -1vw;
  }
  .navbar__div4 > p {
    margin-left: 1vw;
    font-size: 1.6vw;
    font-weight: 500;
    display: none;
  }
  .navbar__logo {
    height: 3.8vw;
  }
  .navbar__div5 {
    /* margin-left: 20vw; */
    margin-top: 0.5vw;
    margin-left: -1.7vw;
  }
  .cmpLogo {
    height: 7vw !important;
  }
  .navbar__div6 {
    display: flex;
    align-items: center;
    position: relative;
    left: -1.5vw;
  }
  .navbar__div6::before {
    content: "";
    width: 19.5vw;
    rotate: 90deg;
    border: 0.2vw solid #333;
    background-color: #333;
    /* margin-left: -7vw;
      position: absolute; */
  }
  .navbar__div6 > p {
    margin-left: 0.8vw;
    font-size: 1.2vw;
    font-weight: 500;
    display: none;
  }
  .second__icon {
    height: 3.8vw;
  }
  
  .navbar__div7 {
    overflow: hidden;
  }
  
  .nav__div000000 {
    display: flex;
    font-size: 1.6vw !important;
  }
  
  .nav__div000000 a {
    margin-left: 2vw;
    margin-top: 2vw;
  }
  .nav__div000000 a:nth-child(1) {
    margin-left: 2vw;
  }
}