.res__div1 {
  /* margin-bottom: 25vw; */
  position: relative;
  margin-top: 0vw;
}
.res__div3 {
  margin-top: 24vw;
  margin-left: 5vw;
  margin-right: 5vw;
}


/* CASE STUDY */


.case_study{
  padding-left: 12.5vw;
  margin-top: 7vw;
}
.case_study > h1{
  font-size: 3vw;
  line-height: 3.3vw;
}
.case_studies{
  margin-top: 3vw;
}
.case{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 5vw;
}
.case_content{
  width: 41vw;
  margin-right: 16vw;
}
.case_content > h2{
  font-size: 2.2vw;
  color: #bd8600;
}
.case_content > p{
  margin-top: 2vw;
  font-size: 1.5vw;
}
#caseImg1{
  background: #fff;
  height: 22vw;
    width: 25vw;
}
#caseImg2{
  width: 25vw;
    height: 21.5vw;
}
#caseImg3{
  width: 25vw;
}

.about__div34_p{
  font-size: 1.2vw;
  margin-top: 1.6vw;
  width: 30vw;
  line-height: 1.7vw;
}

.about__div34 span{
  font-size: 1.2vw;
}


.res__div5 {
  display: flex;
}
.res__div6 > p:nth-child(1) {
  font-size: 3vw;
}
.res__div6 > p:nth-child(2) {
  font-size: 1.3vw;
  margin-top: 2.6vw;
  line-height: 2vw;
}
.res__img1 {
  height: 35vw;
  position: relative;
  left: 2vw;
  top: -8vw;
  z-index: -9999;
}
.res__div030 {
  margin-top: -18vw;
  margin-left: 0vw;
  margin-right: 5vw;
  z-index: 1;
  position: relative;
}
.serviceList{
  margin-top: 1vw;
}
.serviceList ul li{
  margin-top: 0.2vw;
  width: auto;
  font-size: 1.1vw;
}
.res__div015 {
  font-size: 3.6vw;
  width: 45vw;
  line-height: 4vw;
}
.res__div9 {
  position: relative;
}
.res__div015 p > span {
  color: rgba(255, 255, 255, 0.3);
}

.serviceImg{
  width: 48vw;
}

#ser__div35{
  margin-top: -46vw;
}

@media (max-width: 550px) { 
  .serviceList ul li {
    font-size: 4vw;
    margin-top: 4vw;
    width: auto;
}
  .about__div34_p {
    font-size: 4.2vw;
    margin-top: 4vw;
    width: 85vw;
    line-height: 4.8vw;
  }
  .about__div34 span {
    font-size: 4vw;
}
  .res__div1 {
    /* margin-bottom: 25vw; */
    position: relative;
    margin-top: 0vw;
  }
  .res__div3 {
    margin-top: 24vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .res__div5 {
    display: flex;
    top: 5vw;
    position: relative;
  }
  .res__div6 > p:nth-child(1) {
    font-size: 6vw;
    line-height: 6vw;
  }
  .res__div6 > p:nth-child(2) {
    font-size: 3.3vw;
    margin-top: 3.6vw;
    line-height: 3.5vw;
  }
  .res__img1 {
    height: 35vw;
    position: relative;
    left: 2vw;
    top: -8vw;
    z-index: -9999;
    display: none;
  }
  .res__div8 {
    position: relative;
    margin-top: 30vw;
  }
  .res__div030 {
    margin-top: -18vw;
    margin-left: -4vw;
    margin-right: 0vw;
    z-index: 1;
    position: relative;
  }
  .res__div015 {
    font-size: 7.6vw;
    width: 75vw;
    line-height: 8vw;
    position: relative;
  }
  .res__div9 {
    position: relative;
  }
  .res__div015 p > span {
    color: rgba(255, 255, 255, 0.3);
  }
  
  .serviceImg{
    height: 58vw;
    width: 87%;
    margin-left: 6vw;
  }
  
  #ser__div35{
    margin-top: -46vw;
  }

  .about__div1014 {
    margin-left: 7vw;
    margin-top: 7vw;
  } 

  #about__div00160 {
    font-size: 4.2vw;
    margin-top: 5.6vw;
    width: 83vw;
    line-height: 6.4vw;
  }
  #about__div00170 {
    font-size: 4.2vw;
    margin-top: 1.6vw;
    width: 83vw;
    line-height: 6.4vw;
  }

  .about__div32 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vw;
  }
  .about__div32 div {
    margin-left: 2vw;
  }
  
  .about__img11 {
    height: 60vw;
      width: 100%;
  }
  .about__div34 {
    margin-top: 5vw;
    position: relative;
    left: -1.7vw;
  }
  .about__div34 > p:nth-child(1) {
    font-size: 7.5vw;
    margin-top: 1.3vw;
  }
  .about__div34 > p:nth-child(2) {
    font-size: 4.2vw;
    margin-top: 1.6vw;
    width: 83vw;
    line-height: 6.4vw;
  }
  .about__div35 {
    display: flex;
    font-size: 5vw;
    margin-top: 5vw;
    align-items: center;
    position: relative;
    left: -1.7vw;
    margin-bottom: 13vw;
  }
  .about__svg1 {
    margin-left: 2.3vw;
    height: 7.5vw;
  }
  .about__div033 {
    margin-top: 9vw;
  }
  .about__div034 {
    margin-top: 4vw;
    position: relative;
  }
  .about__div034 > p:nth-child(1) {
    font-size: 2vw;
    margin-top: 1.3vw;
    width: 30vw;
  }
  .about__div034 > p:nth-child(2) {
    font-size: 1.2vw;
    margin-top: 1.6vw;
    width: 27vw;
    line-height: 1.7vw;
  }
  .about__div035 {
    display: flex;
    font-size: 1.1vw;
    margin-top: 1.7vw;
    align-items: center;
    position: relative;
  }
  .about__div40 {
    margin-top: 20vw;
  }
  .home__img07 {
    height: 65vw;
    /* position: relative; */
    z-index: -99;
    opacity: 0;
    /* top: -50vw; */
    /* width: 100%; */
  }
  .home__div035 {
    background: linear-gradient(
        0deg,
        #eead0e69 0%,
        rgba(23, 23, 23, 0.7) 30.55%
      ),
      #171717;
    margin-top: -62vw;
    /* position: relative; */
    /* top: -63vw; */
    z-index: -999999;
    /* opacity: 0; */
  }
  .sell__div0011 {
    width: 9.4vw;
    margin-top: 0.2vw;
    border: 0.01vw solid #fff;
  }
  .about__div49 {
    position: relative;
    top: 5vw;
  }

  .case_study {
    padding-left: 6vw;
    margin-top: 5vw;
}
.case_study > h1 {
  font-size: 6.5vw;
  line-height: 6.8vw;
}
.case {
  flex-wrap: wrap;
  margin-top: 5vw;
}
#caseImg1 {
  height: 70vw;
  width: 80vw;
}
#caseImg2 {
  width: 80vw;
  height: 65vw;
}
#caseImg3 {
  width: 80vw;
}
.case_content {
  width: 80vw;
  margin-right: 14vw;
}
.case_content > h2 {
  font-size: 5vw;
  line-height: 5.5vw;
  margin-top: 2vw;
}
.case_content > p {
  font-size: 4vw;
  line-height: 5.5vw;
}
}