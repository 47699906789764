.Footer {
  padding: 3vw 5vw;
  width: 100vw;
  background: #00002c;
  z-index: 8;
  overflow: hidden;
  position: relative;
}

.logo_box > img {
  height: 6vw;
}

.part_1,
.foooter_links {
  display: flex;
}

.part_1 {
  justify-content: space-between;
  z-index: -999;
}

.foooter_links div div {
  margin-top: 2.4vw;
}

.foooter_links h1 {
  font-size: 1.8em;
  font-weight: 400;
}

.foooter_links a {
  text-decoration: none;
}

.foooter_links p {
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 1vw;
  color: #fff;
}

.foooter_links {
  gap: 7vw;
  margin-right: 6vw;
}

.social_media {
  margin-top: 2.5vw;
  display: flex;
  gap: 2vw;
}

.social_media svg {
  font-size: 1.9vw;
  color: #777;
  transition: all ease 0.3s;
}

.social_media svg:hover {
  color: #fff;
}

.part_2 {
  border-top: 1px solid #777;
  margin-top: 4vw;
  padding-top: 2.5vw;
  display: flex;
  justify-content: space-between;
}

.part_2 > h1 {
  font-size: 1.4em;
  margin-bottom: 3vw;
}

.part_2 p {
  font-weight: 400;
  color: #777;
  font-size: 0.8em;
  margin-bottom: 2vw;
}
.part_2 a p{
  font-size: 0.9em;
}



@media screen and (max-width: 550px){
  .foooter_links h1 {
    font-size: 1.2em;
}
.foooter_links p {
  font-size: 1em;
}
.logo_box > img {
  height: 7.2vw;
}
.social_media svg {
  font-size: 4vw;
}
.foooter_links {
  margin-right: 0vw;
}
.part_2 p, .part_2 a p {
  font-size: 0.7em;
  margin-bottom: 1vw;
}
}

@media screen and (max-width: 400px){
  .foooter_links h1 {
    font-size: 1em;
}
.foooter_links p {
  font-size: 0.8em;
}
.part_2 p {
  font-size: 0.6em;
}
}