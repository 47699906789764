.hero__div2 {
  overflow-y: hidden;
  position: fixed;
  z-index: -999;
}
.hero__div00 {
  overflow: hidden;
}
/* .hero__div3 {
  height: 75vw;
  width: 30vw;
  margin-top: 15vw;
  margin-left: 35vw;
  position: relative;
  overflow: hidden;
  transition: 1.3s ease-in-out;
  z-index: -9999;
} */
/* .hero__img1 {
  height: 43vw;
  width: 30vw;
  margin-top: 15vw;
  margin-left: 35vw;
  position: absolute;
  transition: 1.3s ease-in-out;
  z-index: -111;
} */
/* .hero__img2 {
  height: 58vw;
  width: 33vw;
  margin-top: 1vw;
  margin-left: 33vw;
  position: fixed;
  overflow: hidden;
  transition: 1.8s ease-in-out;
  z-index: 1;
} */
.hero__div3 {
  height: 75vw;
  width: 68vw;
  margin-top: 15vw;
  margin-left: 17.5vw;
  position: relative;
  overflow: hidden;
  transition: 1.3s ease-in-out;
  z-index: -9999;
}
.hero__img1 {
  height: 60vw;
  width: 68vw;
  margin-top: 14vw;
  margin-left: 17.5vw;
  position: absolute;
  /* overflow: hidden; */
  transition: 1.3s ease-in-out;
  z-index: -111;
}
.hero__img2 {
  height: 36vw;
  width: 68vw;
  margin-top: 23vw;
  margin-left: 17.5vw;
  position: fixed;
  overflow: hidden;
  transition: 1.8s ease-in-out;
  z-index: 1;
}
.hero__div1 {
  width: 100%;
  overflow-x: hidden;
}

.hero__video {
  overflow: hidden;
  height: 100%;
    width: 100%;
}

#hero__div4{
  position: relative;
}

.tagline{
  position: absolute;
  top: 49vw;
  right: 2vw;
  z-index: 1;
  text-transform: uppercase;
  font-size: 2vw;
  font-weight: 600;
}


@media (max-width: 550px) { 
  .tagline{
    top: 68vw;
    font-size: 4vw;
  }
  
  .hero__div2 {
    overflow-y: visible;
    position: fixed;
    z-index: -999;
  }
  .hero__div00 {
    overflow: hidden;
    position: relative;
    margin-top: 10vw;
  }
  .hero__div3 {
    height: 60vw !important; 
    width: 84vw !important;
    margin-top: 14vw !important;
    margin-left: 8vw !important;
    position: relative;
    overflow: hidden;
    transition: 1.3s ease-in-out;
    z-index: -9999;
  }
  .hero__img1 {
    height: 60vw !important;
    width: 84vw !important;
    margin-top: 14vw;
    margin-left: 8vw !important;
    position: absolute;
    /* overflow: hidden; */
    transition: 1.3s ease-in-out;
    z-index: -111;
  }
  .hero__img2 {
    height: 48vw !important;
    width: 84vw !important;
    margin-top: 32vw !important;
    margin-left: 8vw !important;
    position: fixed;
    overflow: hidden;
    transition: 1.8s ease-in-out;
    z-index: 1;
  }
  .hero__div1 {
    width: 100%;
    overflow-x: hidden;
  }
  
  .hero__video {
    overflow: hidden;
    height: 100%;
      width: 100%;
      display: none;
  }
}