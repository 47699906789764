.about__div1 {
  margin-bottom: 0vw;
  position: relative;
  margin-top: 0vw;
}
.about__img1 {
  height: 38vw;
  opacity: 0.7;
  position: relative;
  margin-top: 5vw;
  width: 100vw;
    object-fit: cover;
}

.about__div4 {
  position: relative;
  margin-top: -23vw;
  margin-left: 5vw;
}

.about__div4 > p:nth-child(1) {
  font-size: 5vw;
}

.about__div4 > p:nth-child(2) {
  font-size: 1.5vw;
  margin-top: 1vw;
}
.about__div5 {
  margin-top: 25vw;
}
.about__div6 {
  display: flex;
}
.about__img2 {
  height: 30vw;
}
.about__div7 {
  margin-left: 5vw;
  margin-top: 1.5vw;
}
.about__div8 {
  font-size: 4.2vw;
  width: 45vw;
  line-height: 5vw;
}
.about__div8 span {
  color: rgba(255, 255, 255, 0.3);
}
.about__div9 {
  width: 40vw;
  margin-top: 3vw;
  font-size: 1.5vw;
  line-height: 2.1vw;
  font-weight: 500;
}
.about__div10 {
  font-size: 1.6vw;
  margin-top: 2.4vw;
}
.about__div11 {
  border: 0.01vw solid #fff;
  opacity: 0.6;
  width: 8.6vw;
  margin-top: 0.3vw;
}
.about__div12 {
  margin-top: 10vw;
}
.residential_div12{
  margin-top: 0vw;
  position: relative;
  top: 12vw;
}

#sesrvice_Img{
  height: 50vw;
}

.about__div13 {
  display: flex;
  justify-content: space-between;
}
.about__div14 {
  margin-left: 4vw;
  margin-top: 2vw;
}
.about__img3 {
  height: 30vw;
}
.about__div15 {
  font-size: 3.7vw;
  width: 40vw;
  line-height: 3.7vw;
}
.about__div16 {
  margin-top: 3vw;
  font-size: 1.4vw;
  font-weight: 500 !important;
  width: 40vw;
  line-height: 2.2vw;
}
.about__div17 {
  margin-top: 3vw;
  font-size: 1.4vw;
  font-weight: 500 !important;
  width: 40vw;
  line-height: 2.2vw;
}
.about__div18 {
  margin-top: 3vw;
  font-size: 1.7vw;
}
.about__div19 {
  border: 0.01vw solid #fff;
  opacity: 0.7;
  width: 8.2vw;
  margin-top: 0.3vw;
}
.about__div21 {
  margin-top: 15vw;
}
.about__img5 {
  height: 38vw;
  margin-left: 55vw;
  z-index: -99999;
  position: relative;
  top: -15vw;
  opacity: 0;
}
.about__div24 {
  margin-top: -26vw;
  margin-left: 5vw;
}
.about__img6 {
  height: 20vw;
  z-index: 1;
  position: relative;
  top: -39vw;
}
.about__img7 {
  height: 42vw;
  z-index: -999;
  position: relative;
  top: -10vw;
  left: -3vw;
  opacity: 0.8;
}
.about__img8 {
  height: 13vw;
  position: relative;
  top: -50vw;
  left: -20vw;
  z-index: 1;
}
.about__div25 {
  font-size: 2.4vw;
  width: 54vw;
  position: relative;
  margin-top: -40vw;
  margin-left: 42vw;
}
.about__div012 {
  margin-top: 20vw;
}
.about__div015 {
  font-size: 4.6vw;
  width: 50vw;
  line-height: 4.7vw;
}
.about__div014 {
  margin-left: 4vw;
  margin-top: -1vw;
}
.about__div05 {
  margin-top: 12vw;
}
.about__div08 {
  font-size: 3.9vw;
  width: 45vw;
  line-height: 5vw;
}
.about__div07 {
  margin-left: 5vw;
  margin-top: 3vw;
}
.about__div26 {
  margin-top: 10vw;

  background: linear-gradient(
      0deg,
      #eead0e69 0%,
      rgba(23, 23, 23, 0.7) 99.34%
    ),
    #171717;
}
.about__img9 {
  margin-left: 31vw;
  height: 50vw;
  position: relative;
  top: 8.3vw;
  /* z-index: -999; */
}
.about__div28 {
  margin-top: -20vw;
}
.blurBg{
  height: 30vw;
  position: relative;
  top: 6vw;
  left: 10vw;
}
.about__div29 {
  position: relative;
  font-size: 2.7vw;
  margin-top: -15vw;
  width: 52vw;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
.about__div29 p {
  position: relative;
  top: -12vw;
}
.about__img10 {
  height: 30vw;
  position: relative;
  top: -2vw;
}
.about__div49{
  margin-left: 5vw;
  margin-top: 5vw;
}
.about__div30 {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 7vw;
}
.about__div32 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 5vw;
}
.about__div32 div {
  margin-left: 2vw;
}
.about__div32 div:nth-child(1) {
  /* margin-left: 0vw; */
}
.about__img11 {
  height: 20vw;
    width: 30vw;
}
.about__div34 {
  margin-top: 2vw;
  position: relative;
  left: -1.7vw;
}
.about__div34 > p:nth-child(1) {
  font-size: 2vw;
  margin-top: 1.3vw;
}
.about__div34 > p:nth-child(2) {
  font-size: 1.2vw;
  margin-top: 1.6vw;
  width: 30vw;
  line-height: 1.7vw;
}
.about__div35 {
  display: flex;
  font-size: 1.1vw;
  margin-top: 1.7vw;
  align-items: center;
  position: relative;
  left: -1.7vw;
}
.about__svg1 {
  margin-left: 0.8vw;
}
.about__div033 {
  margin-top: 9vw;
}
.about__div034 {
  margin-top: 4vw;
  position: relative;
}
.about__div034 > p:nth-child(1) {
  font-size: 2vw;
  margin-top: 1.3vw;
  width: 30vw;
}
.about__div034 > p:nth-child(2) {
  font-size: 1.2vw;
  margin-top: 1.6vw;
  width: 27vw;
  line-height: 1.7vw;
}
.about__div035 {
  display: flex;
  font-size: 1.1vw;
  margin-top: 1.7vw;
  align-items: center;
  position: relative;
}
.about__div40 {
  margin-top: 20vw;
}
.home__img07 {
  height: 65vw;
  /* position: relative; */
  z-index: -99;
  opacity: 0;
  /* top: -50vw; */
  /* width: 100%; */
}
.home__div035 {
  background: linear-gradient(
      0deg,
      #eead0e69 0%,
      rgba(23, 23, 23, 0.7) 30.55%
    ),
    #171717;
  margin-top: -62vw;
  /* position: relative; */
  /* top: -63vw; */
  z-index: -999999;
  /* opacity: 0; */
}
.sell__div0011 {
  width: 9.4vw;
  margin-top: 0.2vw;
  border: 0.01vw solid #fff;
}

@media (max-width: 550px) { 
  .about__div1 {
    margin-bottom: 0vw;
    position: relative;
    margin-top: 0vw;
  }
  .about__img1 {
    height: 100vw;
    opacity: 0.7;
    position: relative;
    margin-top: 20vw;
    width: 100vw;
      object-fit: cover;
      opacity: 0.7;
  }
  
  .about__div4 {
    position: relative;
    margin-top: -37vw;
    margin-left: 5vw;
  }
  
  .about__div4 > p:nth-child(1) {
    font-size: 10vw;
  }
  
  .about__div4 > p:nth-child(2) {
    font-size: 3.3vw;
    margin-top: 1vw;
    line-height: 4vw;
  }
  .about__div5 {
    margin-top: 35vw;
  }
  .about__div6 {
    display: flex;
    flex-direction: column;
  }
  .about__img2 {
    height: 46vw;
    margin-left: 6vw;
  }
  .about__div7 {
    margin-left: 5vw;
    margin-top: 4.5vw;
  }
  .about__div8 {
    font-size: 8.2vw;
    width: 85vw;
    line-height: 8.6vw;
  }
  .about__div8 span {
    color: rgba(255, 255, 255, 0.3);
  }
  .about__div9 {
    width: 80vw;
    margin-top: 3vw;
    font-size: 4.3vw;
    line-height: 6vw;
    font-weight: 500;
  }
  .about__div10 {
    font-size: 1.6vw;
    margin-top: 2.4vw;
  }
  .about__div11 {
    border: 0.01vw solid #fff;
    opacity: 0.6;
    width: 8.6vw;
    margin-top: 0.3vw;
  }
  .about__div12 {
    margin-top: 10vw;
  }
  .residential_div12 {
    top: 0vw;
}
  
  .about__div13 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }
  .about__div14 {
    margin-left: 4vw;
    margin-top: 4.5vw;
  }
  .about__img3 {
    height: 54vw;
    margin-left: 6vw;
  }
  .about__div15 {
    font-size: 8.6vw;
      width: 80vw;
    line-height: 9vw;
  }
  .about__div16 {
    width: 85vw;
    margin-top: 3vw;
    font-size: 4.3vw;
    line-height: 5.5vw;
    font-weight: 500;
  }
  .about__div17 {
    margin-top: 3vw;
    font-size: 4.3vw;
    font-weight: 500 !important;
    width: 85vw;
    line-height: 7vw;
  }
  .about__div18 {
    margin-top: 3vw;
    font-size: 1.7vw;
  }
  .about__div19 {
    border: 0.01vw solid #fff;
    opacity: 0.7;
    width: 8.2vw;
    margin-top: 0.3vw;
  }
  .about__div21 {
    margin-top: 65vw;
  }
  .about__img5 {
    height: 43vw;
    margin-left: 47vw;
    z-index: -99999;
    position: relative;
    display: none;
  }
  .about__div24 {
    margin-top: -20vw;
    margin-left: 5vw;
  }
  .about__img6 {
    height: 50vw;
    z-index: 1;
    position: relative;
    top: -36vw;
    left: 0vw;
  }
  .about__img7 {
    height: 72vw;
    z-index: -999;
    position: relative;
    top: -10vw;
    left: -23vw;
    opacity: 0.9;
    display: none;
  }
  .about__img8 {
    height: 13vw;
    position: relative;
    top: -50vw;
    left: -20vw;
    z-index: 1;
  }
  .about__div25 {
    font-size: 4.8vw;
    width: 84vw;
    position: relative;
    margin-top: -29vw;
    margin-left: 5vw;
    line-height: 7vw;
  }
  .about__div012 {
    margin-top: 12vw;
    margin-left: -0.3vw;
  }
  .about__div015 {
    font-size: 8.6vw;
      width: 80vw;
    line-height: 9vw;
  }
  .about__div014 {
    margin-left: 6vw;
    margin-top: 4.5vw;
  }
  .about__div05 {
    margin-top: 12vw;
  }
  .about__div08 {
    font-size: 8.6vw;
      width: 80vw;
    line-height: 9vw;
  }
  .about__div07 {
    margin-left: 5vw;
    margin-top: 3vw;
  }
  .about__div26 {
    margin-top: 10vw;
  
    background: linear-gradient(
        0deg,
        #eead0e69 0%,
        rgba(23, 23, 23, 0.7) 99.34%
      ),
      #171717;
      display: none;
  }
  .about__img9 {
    margin-left: 31vw;
    height: 50vw;
    position: relative;
    top: 8.3vw;
    /* z-index: -999; */
  }
  .about__div28 {
    margin-top: -20vw;
  }
  .blurBg{
    height: 30vw;
    position: relative;
    top: 6vw;
    left: 10vw;
  }
  .about__div29 {
    position: relative;
    font-size: 2.7vw;
    margin-top: -15vw;
    width: 52vw;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .about__div29 p {
    position: relative;
    top: -12vw;
  }
  .about__img10 {
    height: 30vw;
    position: relative;
    top: -2vw;
  }
  .about__div49{
    margin-left: 5vw;
    margin-top: 5vw;
  }
  .about__div30 {
    margin-top: 10vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 7vw;
  }
  .about__div32 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vw;
  }
  .about__div32 div {
    margin-left: 2vw;
  }
  
  .about__img11 {
    height: 20vw;
      width: 30vw;
  }
  .about__div34 {
    margin-top: 2vw;
    position: relative;
    left: -1.7vw;
  }
  .about__div34 > p:nth-child(1) {
    font-size: 2vw;
    margin-top: 1.3vw;
  }
  .about__div34 > p:nth-child(2) {
    font-size: 1.2vw;
    margin-top: 1.6vw;
    width: 30vw;
    line-height: 1.7vw;
  }
  .about__div35 {
    display: flex;
    font-size: 1.1vw;
    margin-top: 1.7vw;
    align-items: center;
    position: relative;
    left: -1.7vw;
  }
  .about__svg1 {
    margin-left: 0.8vw;
  }
  .about__div033 {
    margin-top: 9vw;
  }
  .about__div034 {
    margin-top: 4vw;
    position: relative;
  }
  .about__div034 > p:nth-child(1) {
    font-size: 2vw;
    margin-top: 1.3vw;
    margin-bottom: 0.3vw;
    width: 30vw;
  }
  .about__div034 > p:nth-child(3) {
    font-size: 1.2vw;
    margin-top: 3vw;
    width: 27vw;
    line-height: 1.7vw;
  }
  .about__div035 {
    display: flex;
    font-size: 1.1vw;
    margin-top: 1.7vw;
    align-items: center;
    position: relative;
  }
  .about__div40 {
    margin-top: 20vw;
  }
  .home__img07 {
    height: 65vw;
    /* position: relative; */
    z-index: -99;
    opacity: 0;
    /* top: -50vw; */
    /* width: 100%; */
  }
  .home__div035 {
    background: linear-gradient(
        0deg,
        #eead0e69 0%,
        rgba(23, 23, 23, 0.7) 30.55%
      ),
      #171717;
    margin-top: -62vw;
    /* position: relative; */
    /* top: -63vw; */
    z-index: -999999;
    /* opacity: 0; */
  }
  .sell__div0011 {
    width: 9.4vw;
    margin-top: 0.2vw;
    border: 0.01vw solid #fff;
  }
  .about__div49 {
    position: relative;
    top: 5vw;
  }
}