.Blog {
  padding: 4vw 0;
  display: flex;
  gap: 1.8vw;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  /* width: 98vw; */
}
#Blog{
  margin-top: 7vw;
}
.blog__div1 {
  background: #fff;
  padding-bottom: 2vw;
}
.blog__div2 {
  color: #000;
  margin-left: 13vw;
}
.blog__div2 div {
  width: 23vw;
  height: 23vw;
}
.Blog div {
  width: 23vw;
  height: 23vw;
}

.Blog div img {
  height: 12vw;
  border-radius: 5px;
}

.Blog div h1 {
  color: #000;
  font-size: 1.1em;
  margin-top: 1vw;
}

.Blog div p {
  color: #000;
  margin-top: 0.6vw;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 64px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.blog__div2 div img {
  height: 12vw;
  border-radius: 5px;
}

.blog__div2 div h1 {
  color: #000;
  font-size: 1.1em;
  margin-top: 1vw;
}

.blog__div2 div p {
  color: #000;
  margin-top: 0.6vw;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 64px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1300px){
  .Blog div {
    height: 26vw;
  }
}
@media screen and (max-width: 1200px){
  .Blog div {
    height: 30vw;
    width: 28vw;
  }
  .Blog div img {
    height: 15vw;
}
}
@media screen and (max-width: 1000px){
  .Blog div {
    height: 35vw;
    width: 30vw;
}
  .Blog div img {
    height: 16vw;
}
.Blog div h1 {
  font-size: 1em;
  margin-top: 0vw;
}
.Blog div p {
  margin-top: 0vw;
  font-size: 0.8em;
}
}
@media screen and (max-width: 700px){
  .Blog div {
    height: 40vw;
}
}

@media (max-width: 550px) { 

  #Blog{
    margin-top: 0rem;
  }
  .Blog{
    align-items: center;
  }
  .Blog div img {
    height: 46vw;
    border-radius: 5px;
}


  .blog__div1 {
    margin-left: 6vw;
    margin-right: 6vw;
    position: relative;
    top: 25vw;
    padding-bottom: 45vw;
  }
  .blog__div000 {
    color: #000;
    background: #fff;
  }
  .Blog {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 11vw;
    /* top: 10vw; */
  } 
  .Blog div {
    margin-left: 0vw;
    margin-top: 15vw;
    width: 82vw;
    height: 75vw;
;
  }
  .Blog div:nth-child(1) {
    margin-left: 0vw;
    margin-top: 2.5vw;
  }
  .blog__img01 {
    height: 50vw;
    border-radius: 0.5vw;
  }
  
  .blog__div002 h1 {
    font-size: 5.2vw;
    width: 90vw;
    margin-top: 2.5vw;
  }
  
  .blog__div002 p {
    font-size: 3.7vw;
    line-height: 6vw;
    width: 90vw;
    margin-top: 2.5vw;
  }
  
  .pppp {
    margin-top: 4.3vw;
    font-size: 4vw;
  }
  
  .blooog {
    margin-top: 15vw;
  }

  .Blog div h1 {
    font-size: 1.1em;
    margin-top: 1vw;
}

  .Blog div p {
    margin-top: 0.6vw;
    font-size: 0.9em;
}
}

@media screen and (max-width: 450px){
  .Blog div {
    height: 85vw;
  }
}