.first__div1 {
    background-color: #fff;
    color: #000;
    height: 100%;
    position: relative;
    /* height: 130vw; */
}
.first__div2 {
    position: relative;
    top: 9vw;
    margin-left: 14vw;
    margin-right: 14vw;
    height: 100%;
    padding-bottom: 13vw;
}
.first__img1 {
    height: 35vw;
    border-radius: 1vw;
}

.first__div4 {
    margin-top: 1vw;
    font-size: 2vw;
}

.first__div5 {
    margin-top: 1vw;
    width: 60vw;
    font-size: 1.1vw;
    line-height: 1.6vw;
    position: relative;
    height: 100%;
    /* margin-bottom: 3vw; */
}
.first__div5 p:nth-child(1) {
    margin-top: 0vw;
}
.first__div5 p {
    margin-top: 1.3vw;
}

@media (max-width: 550px) { 
    .first__div1 {
        background-color: #fff;
        color: #000;
        height: 100%;
        position: relative;
        /* height: 130vw; */
    }
    .first__div2 {
        position: relative;
        top: 25vw;
        margin-left: 5vw;
        margin-right: 8vw;
        height: 100%;
        padding-bottom: 40vw;
    }
    .first__img1 {
        height: 50vw;
        border-radius: 1vw;
    }
    
    .first__div4 {
        margin-top: 1vw;
        font-size: 4.2vw;
        margin-left: 1vw;
    }
    
    .first__div5 {
        margin-top: 1vw;
        width: 80vw;
        font-size: 3vw;
        line-height: 5.3vw;
        position: relative;
        height: 100%;
        margin-left: 1.4vw;
        /* margin-bottom: 3vw; */
    }
    .first__div5 p:nth-child(1) {
        margin-top: 3vw;
    }
    .first__div5 p {
        margin-top: 3.3vw;
    }

    .first__div5 ul li{
        width: 100%;
    line-height: 4vw;
    }
    
}