.ham__div1 {
  display: none;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* position: absolute; */
}
.ham__div2 {
  background: #1a1a1a;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  /* transition: 0.8s ease; */
  /* transition: 0.8s ease-in-out; */
}

.ham__div3 {
  background-color: #00002c;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  /* transition: 0.8s ease; */
  /* transition: 0.8s ease-in-out; */
}
.ham__div5 {
  position: absolute;
  top: 10vw;
  margin-left: 2.5vw;
  z-index: 9999;
  /* overflow: scroll; */
  transform: translate(0, 0);
}

.ham__div6 {
  /* display: flex; */
  position: absolute;
  justify-content: space-between;
}

.ham__div7 {
  font-size: 5vw;
  margin-left: 10vw;
}

.ham__div7 p:nth-child(1) {
  margin-top: 0vw;
}

.ham__div7 p {
  margin-top: 0.5vw;
}

.ham__link {
  text-decoration: none;
  color: #fff;
}


@media (max-width: 550px) {  
  .ham__div1 {
    display: none;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
    /* position: absolute; */
  }
  .ham__div2 {
    background: #1a1a1a;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;
    /* transition: 0.8s ease; */
    /* transition: 0.8s ease-in-out; */
  }
  
  .ham__div3 {
    background-color: #00002c;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    /* transition: 0.8s ease; */
    /* transition: 0.8s ease-in-out; */
  }
  .ham__div5 {
    position: absolute;
    top: 30vw;
    margin-left: 2.5vw;
    z-index: 9999;
    /* overflow: scroll; */
    transform: translate(0, 0);
  }
  
  .ham__div6 {
    /* display: flex; */
    position: absolute;
    justify-content: space-between;
  }
  
  .ham__div7 {
    font-size: 14vw;
    width: 75vw;
    margin-left: 10vw;
  }
  
  .ham__div7 p:nth-child(1) {
    margin-top: 0vw;
    width: 100vw;
  }
  
  .ham__div7 p {
    margin-top: 1.8vw;
  }
  
  .ham__link {
    text-decoration: none;
    color: #fff;
  }
  .nav__div000000 a p {
    font-size: 5vw;
  }
  .pp {
    margin-left: 1.2vw;
  }
  .nav__div000000 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5vw;
  }
}