/* POPUP FORM  */

.PopForm{
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999999999999 !important;
  overflow: hidden;
  transition: all ease 0.3s;
}
.PopForm > form{
  display: flex;
  align-items: center;
  flex-flow: column;
  position: relative;
  background: #fff;
  border-radius: 0.9vw;
  margin: 2vw;
  padding: 2vw;
  padding-top: 3vw;
}
.PopForm > form > .popClose{
  position: absolute;
  top: 1vw;
  right: 1.7vw;
  color: #000;
  cursor: pointer;
}
.PopForm > form > .popClose svg{
  font-size: 1.7vw !important;
}
.PopForm label{
  font-size: 1vw;
  line-height: 160%;
  font-weight: 500;
  /* color: #F4F0E1; */
  color: #00001a;
  font-weight: 500;
  font-family: "Manrope";
  text-align: start;
  width: 100%;
}

.PopForm label > span{
  color: #ff100d;
  font-size: 13px;
}

.PopForm input, .PopForm textarea{
  width: 24vw;
  height: 2.5vw;
  background: rgb(242, 242, 242);
  border: 1px solid #E2E2E2;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 20px;
  font-size: 1vw;
  line-height: 160%;
  font-weight: 300;
  letter-spacing: .01em;
  outline: none;
}

.PopForm textarea{
  height: 10vw;
  padding-top: 10px;
}
.PopForm button{
  outline: none;
    border: none;
    color: #fff;
    background: #9B8168;
    margin-top: 1.5vw;
    padding: 0.5vw 2vw;
    font-family: "Manrope";
    font-size: 1vw;
    font-weight: 700;
    transition: all ease 0.3s;
    cursor: pointer;
}

.home__div1 {
  z-index: -99999;
  width: 100%;
  overflow: hidden;
}
.home__div2 {
  margin-top: 10vw;
  /* top: 5vw; */
  z-index: -9999;
  width: 100%;
  overflow-x: hidden;
  /* background-color: #171717; */
  background-color: #00001a;
  color: #fff;
  margin-top: 75vw;
}
.home__div3 {
  z-index: -9999;
}
.home__div4 {
  display: flex;
  justify-content: space-between;
  margin-left: 5vw;
  margin-top: 7vw;
  z-index: -9999;
}
.home__div6 {
  font-size: 3.3vw;
  font-weight: 600;
  line-height: 4vw;
  letter-spacing: 0.01vw;
}
.home__div6 > p:nth-child(1) {
  color: rgba(255, 255, 255, 0.3);
  /* color: #abb8c3; */
}
.home__div7 {
  width: 42vw;
  font-size: 1.4vw;
  margin-top: 2.5vw;
}
.home__div8 {
  margin-top: 1.7vw;
  font-size: 1.5vw;
  border-bottom: 0.01vw solid #fff;
  width: 7.4vw;
}
.home__div9 {
  /* margin-left: 7vw; */
  position: relative;
  left: -4vw;
  /* left: -110vw; */
}
.home__divext1:nth-child(1) {
  margin-top: 0vw;
}
.home__divext1 {
  margin-top: 3vw;
}
.home__div10 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__div12 {
  display: flex;
  align-items: center;
}
.home__img1 {
  height: 1.6vw;
}
.home__div12 > p {
  margin-left: 0.3vw;
  font-size: 1.6vw;
  font-weight: 600;
}
.lost {
  font-size: 1.1vw;
  margin-top: 0.5vw;
  margin-left: 0.4vw;
  width: 30vw;
}
.home__div13 {
  margin-left: 7vw;
}
.home__div14 {
  border: 0.01vw solid #333;
  margin-top: 2vw;
}
.home__div15 {
  margin-left: 6vw;
}
.bg__img1 {
  height: 32vw;
    margin-top: -2vw;
    z-index: -99999;
}
.bg__img2 {
  height: 19vw;
  position: relative;
  top: -16vw;
}
.home__div17 {
  position: relative;
  top: -15vw;
  z-index: 1;
  margin-left: -4.2vw;
}
.home__div18 {
  display: flex;
}
.home__img2 {
  height: 18.5vw;
  z-index: 1;
}
.home__img3 {
  height: 24vw;
  z-index: -99999999;
}
.home__div199 {
  position: relative;
  left: 3vw;
  z-index: 1;
}
.home__div19:nth-child(2) {
  position: relative;
  top: -3vw;
  /* z-index: -9999; */
}
.home__div1999 {
  position: relative;
  left: 0.5vw;
  z-index: 1;
}
.home__div20 {
  display: flex;
  font-size: 1.5vw;
  font-weight: 600;
  align-items: center;
  position: relative;
  top: -4vw;
  left: 12vw;
}
.home__div200 {
  display: flex;
  font-size: 1.5vw;
  font-weight: 600;
  align-items: center;
  position: relative;
  top: -4vw;
  left: 20vw;
}
.home__svg1 {
  margin-left: 1vw;
}
.home__div21 {
  margin-top: -9vw;
    margin-left: 15vw;
    margin-bottom: 5vw;
}
.home__img4 {
  height: 22vw;
    margin-bottom: 3vw;
    margin-left: 5vw;
}
.home__div23 {
  width: 55vw;
    text-align: center;
    font-size: 1.6vw;
    font-weight: 600;
    position: relative;
    top: -17vw;
    left: 7vw;
}
.home__div24 {
  margin-left: 5vw;
  position: relative;
  top: -10vw;
}
.home__div26 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__div26 p:nth-child(1) {
  font-size: 4vw;
  font-weight: 600;
  letter-spacing: 0.05vw;
}
.home__div2600 {
  position: relative;
  left: -3vw;
}
.home__div2600 p:nth-child(1) {
  font-size: 1.5vw;
}
.empty {
  border: 0.01vw solid #fff;
}
.home__img5,
.home__img501,
.home__div301,
.home__div30 {
  pointer-events: none;
}
.home__img5 {
  height: 33vw;
  opacity: 0.85;
  pointer-events: none;
}
.home__img501 {
  height: 27vw;
  opacity: 0.85;
}
.home__div27 {
  width: 200vw;
}
.home__div28 {
  display: flex;
  /* overflow: hidden; */
  /* pointer-events: none; */
  cursor: grab;
  width: 200vw;
}
.home__div28 div:nth-child(1) {
  margin-left: 0vw;
}
.home__div28 div {
  margin-left: 3vw;
}
.home__div30 {
  position: relative;
  top: -10vw;
  left: -0.7vw;
}
.home__div30 > p {
  font-size: 1.5vw;
  font-weight: 600;
  color: #fff;
}
.home__div301 {
  position: relative;
  top: -10vw;
  left: 0vw;
}
.home__div301 > p {
  font-size: 1.5vw;
  font-weight: 600;
  color: #fff;
}
.home__div31 {
  display: flex;
  position: relative;
  left: -1.7vw;
}
.home__div31 > p {
  margin-left: 1.5vw;
  font-size: 1.3vw;
  color: #fff;
}
.home__div32 {
  border: 0.01vw solid #fff;
  rotate: 90deg;
}
.home__div33 {
  border: 0.01vw solid #333;
  width: 9vw;
  background: #000;
  border-radius: 2vw;
  padding: 0.5vw;
  text-align: center;
  font-weight: 600;
  font-size: 1vw;
  margin-top: 1.5vw;
  position: relative;
  left: -3vw;
}
.home__div34 {
  z-index: -9999;
  pointer-events: none;
}


/* Group Companies */

.grp_companies{
  padding-left: 8vw;
    margin-top: -5vw;
    position: relative;
    top: -6vw;
}

.grp_companies > h1{
  font-size: 2.5vw;
}

.companies > h2{
  font-size: 1.8vw;
    font-weight: 400;
    margin-top: 0.4vw;
    margin-bottom: 1vw;
}
.companies > a{
  text-decoration: none;
  color: #fff;
  /* background: #161616; */
  padding: 0.3vw 2vw;
  font-size: 1.2vw;
  line-height: 1.5vw;
  border-radius: 1vw 8vw 8vw 1vw;
}

.company_flex{
  display: flex;
  gap: 3vw;
  align-items: center;
  justify-content: space-between;
    padding-right: 10vw;
    margin-top: 2vw;
}

.img{
  position: relative;
  height: 14vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
}
.img:hover{
  transform: scale(1.1);
}

.sister_svg{
  position: absolute;
  right: 1vw;
  bottom: -1.4vw;
  height: 4vw;
  width: 4vw;
}

#grpImg1 > img {
  width: 26vw;
  height: 14vw;
}
#grpImg2{
  background: #fff;
}
#grpImg2 > img{
  width: 26vw;
  padding: 0 1vw;
}
#grpImg3 > img{
  background: #fff;
    height: 14vw;
    width: 26vw;
    padding: 1vw 4vw;
}


.home__div35 {
  background: linear-gradient( 0deg, #eead0e69 0%, #171717b3 30.55% ), #171717;
  margin-top: -62vw;
  /* position: relative; */
  /* top: -63vw; */
  z-index: -999999;
}
.home__img7 {
  height: 60vw;
  /* position: relative; */
  z-index: -99;
  /* top: -50vw; */
  /* width: 100%; */
}

.home__div37 {
  /* position: relative; */
  overflow-x: hidden;
  top: -63vw;
  display: flex;
  justify-content: space-between;
  margin-left: 2vw;
}
.home__div38 {
  position: relative;
  padding-top: 2vw;
  /* top: 3vw; */
}
.home__div39 {
  display: flex;
  justify-content: space-between;
  position: relative;
  /* top: 5vw; */
}
.home__div40:nth-child(2) {
  margin-left: 5vw;
}
.home__div41 {
  margin-top: 3vw;
  margin-left: 5vw;
}
.home__div41 div:nth-child(odd) {
  margin-left: 0vw;
}
.home__div41 p:nth-child(1) {
  font-size: 1.8vw;
  font-weight: 600;
}
.home__div41 p {
  font-size: 1.4vw;
  margin-top: 1vw;
}
.home__div42 {
  border: 0.01vw solid #333;
  margin-top: 4vw;
  width: 22vw;
  position: relative;
}
.home__img8 {
  height: 33vw;
  width: 30vw;
}
.home__div44 {
  background: #bd8600;  
  padding: 5.3vw;
}
.home__div45 > p {
  font-size: 1.3vw;
}
.home__div46 {
  display: flex;
  margin-top: 1vw;
  font-size: 1.5vw;
  align-items: center;
}
.home__div47 {
  /* position: relative; */
  /* top: -55vw; */
}
.home__svg3 {
  margin-left: 1vw;
}

.home__img9 {
  height: 65vw;
  position: relative;
  z-index: -999;
}
.home__div49 {
  /* position: relative; */
  /* margin-top: -30vw; */
  /* top: -58vw; */
  margin-top: 5vw;
  margin-left: 5vw;
}
.home__div50 > p {
  font-size: 2.4vw;
  font-weight: 600;
}
.home__div51 {
  margin-left: -5vw;
  margin-top: 2.5vw;
}
.home__div51 p {
  font-size: 6.5vw;
  font-weight: 600;
  color: #373737;
  margin-left: 2.3vw;
}
.clientImg{
  height: 5.5vw;
  margin: 0 1.8vw;
}
.home__div52 {
  margin-left: 53vw;
}
.home__div53 {
  margin-top: 8vw;
  /* position: relative; */
  /* top: -52vw; */
  overflow: hidden;
}
.home__img10 {
  height: 35vw;
  opacity: 0.5;
}
.slider_title{
  margin-left: 5vw;
}
.home__div54 {
  /* position: relative; */
  margin-top: -18vw;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 6vw;
}
.home__div56 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home__div56 > p {
  font-size: 3vw;
  font-weight: 600;
}
.home__div57 {
  font-size: 1.6vw;
}
.home__div58 {
  margin-top: 3vw;
}

.home__img12 {
  height: 11vw;
}
.home__div60 {
  display: flex;
  justify-content: space-between;
}
.home__div62 {
  margin-top: 1vw;
}
.home__div63 {
  width: 18vw;
  font-size: 1.5vw;
  font-weight: 600;
}
.home__div64 {
  margin-top: 1vw;
  font-size: 1.2vw;
  width: 17vw;
}
.home__div65 {
  display: flex;
  align-items: center;
  margin-top: 1vw;
}
.home__div65 > p {
  font-size: 1.1vw;
  font-weight: 600;
}
.home__div65 > svg {
  margin-left: 1vw;
}
.home__div70 {
  border: 0.01vw solid #333;
}


@media screen and (max-width: 1024px){
  .home__div2 {
    margin-top: 55vw;
}
.lost {
  font-size: 1.3vw;
}
.home__div7 {
  font-size: 1.6vw;
}
}

@media screen and (max-width: 800px){
  .home__div4 {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 5vw;
}
  .home__div5{
    width: 100vw;
  }
  .home__div7 {
    font-size: 2vw;
    margin: 2vw 5vw;
    width: auto;
    text-align: center;
}
.home__div6 {
  text-align: center;
  font-size: 3.5vw;
    line-height: 4.5vw;
}
.home__div9 {
  top: 5vw;
  width: 100%;
  margin-left: 5vw;
  margin-right: 5vw;
  left: 0;
}
.home__div12 > p {
  font-size: 2.5vw;
}
.lost{
  font-size: 1.9vw;
    margin-top: 1vw;
    width: auto;
}
.home__div14 {
  margin-top: 1vw;
}
.home__div15 {
  margin-top: 10vw;
}
.home__div21 {
  margin-top: -15vw;
}
}


@media (max-width: 550px) { 
  .sister_svg {
    bottom: -4vw;
    height: 10vw;
    width: 10vw;
}
  .PopForm > form{
    border-radius: 0.9vw;
    margin: 2vw;
    padding: 3vw;
    padding-top: 5vw;
  }
  .PopForm > form > .popClose{
    top: 1vw;
    right: 1.7vw;
  }
  .PopForm > form > .popClose svg{
    font-size: 5vw !important;
  }
  .PopForm label{
    font-size: 3vw;
    line-height: 160%;
  }
  
  .PopForm label > span{
    font-size: 3vw;
  }
  
  .PopForm input, .PopForm textarea{
    width: 80vw;
    height: 6vw;
    border-radius: 5px;
    margin-top: 1vw;
    margin-bottom: 1vw;
    padding: 0 10px;
    font-size: 2.8vw;
    line-height: 160%;
  }
  
  .PopForm textarea{
    height: 25vw;
    padding-top: 10px;
  }
  .PopForm button{
      margin-top: 1.8vw;
      padding: 0.8vw 3vw;
      font-size: 2.8vw;
  }
  .home__div1 {
    z-index: -99999;
    width: 100%;
    overflow: hidden;
  }
  .home__div2 {
    /* margin-top: 30vw; */
    /* top: 5vw; */
    /* z-index: -9999; */
    width: 100%;
    overflow-x: hidden;
    /* background-color: #171717; */
    /* background-color: #00001a; */
    color: #fff;
    margin-top: 75vw;
  }
  .home__div3 {
    /* z-index: -9999; */
  }
  .home__div4 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 7vw;
    margin-top: 5vw;
    /* z-index: -9999; */
  }
  .home__div6 {
    font-size: 6.7vw;
    width: 85vw;
    font-weight: 600;
    line-height: 8vw;
    letter-spacing: 0.02vw;
    text-align: left;
  }
  .home__div6 > p:nth-child(1) {
    color: rgba(255, 255, 255, 0.3);
    /* color: #abb8c3; */
  }
  .home__div7 {
    width: 85vw;
    font-size: 3.7vw;
    line-height: 4.8vw;
    margin-top: 2.5vw;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
  .home__div8 {
    margin-top: 1.7vw;
    font-size: 1.5vw;
    border-bottom: 0.01vw solid #fff;
    width: 7.4vw;
  }
  .home__div9 {
    margin-left: 0;
    position: relative;
    left: 1vw;
    margin-top: 9vw;
    top: 0;
  }
  .home__divext1:nth-child(1) {
    margin-top: 0vw;
  }
  .home__divext1 {
    margin-top: 6vw;
  }
  .home__div10 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home__div11 {
    margin-left: -0.8vw;
  }
  .home__div12 {
    display: flex;
    align-items: center;
  }
  .home__svg0002 {
    height: 8vw;
  }
  .home__img1 {
    height: 1.6vw;
  }
  .home__div12 > p {
    margin-left: 0.3vw;
    font-size: 5vw;
    font-weight: 600;
  }
  .lost {
    font-size: 3vw;
    margin-top: 1.2vw;
    margin-left: 0.4vw;
    width: 60vw;
    font-weight: 500;
    line-height: 3.5vw;
  }
  .home__div13 {
    /* margin-left: -2vw; */
    position: relative;
    left: -5vw;
  }
  .home__div14 {
    border: 0.01vw solid #333;
    margin-top: 2vw;
    position: relative;
    width: 84vw;
  }
  .home__div15 {
    margin-top: 15vw;
    margin-left: 6vw;
  }
  .bg__img1 {
    height: 88vw;
    z-index: -99999;
    display: none;
  }
  .bg__img2 {
    height: 19vw;
    position: relative;
    top: -16vw;
  }
  .home__div17 {
    position: relative;
    top: -1vw;
    z-index: 1;
    margin-left: 0.2vw;
  }
  .home__div18 {
    display: flex;
    flex-direction: column;
    margin-left: -0.5vw;
  }
  .home__img2 {
    height: 54.5vw;
    z-index: 1;
    margin-left: 3vw;
  }
  .home__img1{
    margin-left: 0 !important;
  }
  .home__img3 {
    height: 54.5vw;
    z-index: -99999999;
    margin-left: 3vw;
  }
  .home__div199 {
    position: relative;
    left: 3vw;
    z-index: 1;
  }
  .home__div19:nth-child(2) {
    position: relative;
    top: 0vw;
    /* z-index: -9999; */
  }
  .home__div1999 {
    position: relative;
    left: 0.5vw;
    z-index: 1;
  }
  .home__div20 {
    display: flex;
    font-size: 5.5vw;
    font-weight: 600;
    align-items: center;
    position: relative;
    top: -11vw;
    left: 47vw;
  }
  .home__div200 {
    display: flex;
    font-size: 5.5vw;
    font-weight: 600;
    align-items: center;
    position: relative;
    top: -11vw;
    left: 42vw;
  }
  .home__svg1 {
    margin-left: 1vw;
    height: 5.5vw;
  }
  .home__div21 {
    margin-top: 10vw;
    margin-left: 7vw;
    margin-bottom: 0vw;
    display: none;
  }
  .home__img4 {
    height: 35vw;
  }
  .home__div23 {
    width: 84vw;
    text-align: center;
    font-size: 4vw;
    font-weight: 600;
    position: relative;
    top: -30vw;
    left: 2vw;
  }
  .home__div24 {
    margin-left: 5vw;
    position: relative;
    top: -10vw;
  }
  .home__div26 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home__div26 p:nth-child(1) {
    font-size: 7vw;
    font-weight: 600;
    letter-spacing: 0.05vw;
  }
  .home__extr3 {
    position: relative;
    top: 3vw;
  }
  .home__div2600 {
    position: relative;
    left: -3vw;
  }
  .home__div2600 p:nth-child(1) {
    font-size: 1.5vw;
  }
  .empty {
    border: 0.01vw solid #fff;
  }
  .home__img5,
  .home__img501,
  .home__div301,
  .home__div30 {
    pointer-events: none;
  }
  .home__img5 {
    height: 68vw;
    opacity: 0.8;
    pointer-events: none;
  }
  .home__img501 {
    height: 68vw;
    opacity: 0.8;
  }
  .home__div27 {
    width: 200vw;
  }
  .home__div28 {
    display: flex;
    /* overflow: hidden; */
    /* pointer-events: none; */
    cursor: grab;
    width: 200vw;
  }
  .home__div28 div:nth-child(1) {
    margin-left: 0vw;
  }
  .home__div28 div {
    margin-left: 3vw;
  }
  .home__div30 {
    position: relative;
    top: -18vw;
    left: 1vw;
  }
  .home__div30 > p {
    font-size: 4.5vw;
    font-weight: 600;
    color: #fff;
  }
  .home__div301 {
    position: relative;
    top: -18vw;
    left: 1vw;
  }
  .home__div301 > p {
    font-size: 4.5vw;
    font-weight: 600;
    color: #fff;
  }
  .home__div31 {
    display: flex;
    position: relative;
    left: 0.6vw;
  }
  .home__div31 > p {
    margin-left: 1.8vw;
    font-size: 2.1vw;
    color: #fff;
  }
  .home__div32 {
    border: 0.01vw solid #fff;
    rotate: 90deg;
  }
  .home__div33 {
    border: 0.01vw solid #333;
    width: 15vw;
    background: #000;
    border-radius: 2vw;
    padding: 1.1vw;
    text-align: center;
    font-weight: 600;
    font-size: 1.5vw;
    margin-top: 1.5vw;
    position: relative;
    left: -3vw;
  }
  .home__div34 {
    display: none;
  }
  .home__div35 {
    background: linear-gradient( 0deg, #eead0e69 0%, #171717b3 30.55% ), #171717;
    margin-top: -62vw;
    /* position: relative; */
    /* top: -63vw; */
    z-index: -999999;
  }
  .home__img7 {
    height: 65vw;
    /* position: relative; */
    z-index: -99;
    /* top: -50vw; */
    /* width: 100%; */
  }
  
  .home__div37 {
    /* position: relative; */
    overflow-x: hidden;
    top: -63vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 0vw;
  }
  .home__div38 {
    position: relative;
    padding-top: 2vw;
    /* top: 3vw; */
  }
  .home__div39 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    /* top: 5vw; */
  }
  .home__div40:nth-child(2) {
    margin-left: 0vw;
  }
  .home__div41 {
    margin-top: 6vw;
    margin-left: 8vw;
  }
  .home__div41 div:nth-child(odd) {
    position: relative;
    /* margin-left: -5vw; */
  }
  .home__div41 p:nth-child(1) {
    font-size: 5.8vw;
    font-weight: 600;
  }
  .home__div41 p {
    font-size: 3.7vw;
    margin-top: 2.9vw;
  }
  .home__div42 {
    border: 0.01vw solid #333;
    margin-top: 4vw;
    width: 90vw;
    position: relative;
  }
  .home__img8 {
    height: 33vw;
    width: 30vw;
    display: none;
  }
  .home__div44 {
    background: #bd8600;  
    padding: 10.3vw;
    margin-top: 6vw;
    position: relative;
  }
  .home__div45 > p {
    font-size: 3.5vw;
  }
  .home__div46 {
    display: flex;
    margin-top: 1.8vw;
    font-size: 3vw;
    align-items: center;
  }
  .home__div47 {
    /* position: relative; */
    /* top: -55vw; */
  }
  .home__svg3 {
    margin-left: 1vw;
    height: 4.3vw;
  }
  
  .home__img9 {
    height: 65vw;
    position: relative;
    z-index: -999;
  }
  .home__div49 {
    margin-top: 5vw;
    margin-left: 5vw;
  }
  .home__div50 > p {
    font-size: 5.4vw;
    font-weight: 600;
  }
  .home__div51 {
    margin-left: -5vw;
    margin-top: 4.5vw;
  }
  .home__div51 p {
    font-size: 9.5vw;
    font-weight: 600;
    color: #373737;
    margin-left: 2.3vw;
  }
  .home__div52 {
    margin-left: 53vw;
  }
  .home__div53 {
    margin-top: 12vw;
    /* position: relative; */
    /* top: -52vw; */
    overflow: hidden;
  }
  .home__img10 {
    height: 40vw;
  }
  .slider_title{
    margin-left: 5vw;
  }
  .home__div54 {
    /* position: relative; */
    top: -120vw;
    margin-top: -10vw;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 0vw;
  }
  .clientImg {
    height: 12.5vw;
    margin: 0 3vw;
}
  .home__div56 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home__div56 > p {
    font-size: 4.8vw;
    font-weight: 600;
  }
  .home__div57 {
    font-size: 3.6vw;
  }
  .home__div58 {
    margin-top: 3vw;
  }
  
  .home__img12 {
    height: 50vw;
  }
  .home__div60 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .home__div61 {
    margin-top: 4vw;
  }
  .home__div62 {
    margin-top: 1vw;
  }
  .home__div63 {
    width: 75vw;
    font-size: 6vw;
    font-weight: 600;
    line-height: 6.2vw;
    margin-top: 3vw;
  }
  .home__div64 {
    margin-top: 4vw;
    font-size: 4.2vw;
    width: 84vw;
  }
  .home__div65 {
    display: flex;
    align-items: center;
    margin-top: 4vw;
    margin-bottom: 13vw;
  }
  .home__div65 > p {
    font-size: 4vw;
    font-weight: 600;
  }
  .home__div65 > svg {
    margin-left: 1vw;
  }
  .home__div70 {
    border: 0.01vw solid #333;
  }
  .blooog__svg002 {
    height: 6vw;
  }
  .grp_companies {
    margin-top: 5vw;
}
  .grp_companies > h1 {
    font-size: 6vw;
    line-height: 6.3vw;
    margin-bottom: 2vw;
}
.img {
  height: 40vw;
}
#grpImg1 > img {
  width: 75vw;
  height: 40vw;
}
.company_flex {
  flex-wrap: wrap;
  gap: 0vw;
}
.companies > h2 {
  font-size: 5vw;
  line-height: 5.2vw;
  margin-top: 1.5vw;
  margin-bottom: 2.5vw;
}
.companies > a {
  padding: 0.7vw 5vw;
  font-size: 4vw;
  line-height: 4.3vw;
  /* background: #bd8600; */
}
#grpImg2 > img {
  width: 75vw;
  padding: 0 2vw;
}
#grpImg3 > img {
  background: #fff;
  height: 42vw;
  width: 75vw;
  padding: 1vw 8vw;
}
}

