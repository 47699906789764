@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}

body {
  background-color: #00001a;
  color: #fff;
  overflow-x: hidden;
  font-family: 'Heebo', sans-serif;
}

body::-webkit-scrollbar {
  width: 0vw;
}